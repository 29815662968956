import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useQuery } from "@apollo/client"
import { ABOUT_US_QUERY } from "../components/graphql"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import path_img from "../assets/images/studydetail/path-1179@1x.png"
import quote from "../assets/images/quote.png"
import BounceIndicator from "react-activity/lib/Bounce"

const AboutUS = ({ data }) => {
  const aboutuspageContent = useQuery(ABOUT_US_QUERY)
  const [value, setValue] = useState(0)

  const { allStrapiAboutUsPage, allStrapiHomePage } = data
  const seoTitle =
    allStrapiAboutUsPage !== null
      ? allStrapiAboutUsPage.edges[0].node.title
      : ""
  const seoMetaDescription =
    allStrapiAboutUsPage !== null
      ? allStrapiAboutUsPage.edges[0].node.metaDescription
      : ""
  const seoImage =
    allStrapiHomePage !== null
      ? allStrapiHomePage.edges[0].node.metaImage
        ? allStrapiHomePage.edges[0].node.metaImage.url
        : ""
      : ""

  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      if (typeof document !== `undefined`) {
        localStorage.setItem('lastRevistedPage',window.location.pathname)
      }
    }
  });
  if (aboutuspageContent.loading)
    return (
      <>
        <SEO
          title={seoTitle}
          description={seoMetaDescription}
          image={seoImage}
        />
        <div className={"centered"}>
          <BounceIndicator size={25} color={"#0055a4"} />
        </div>
      </>
    )
  return (
    <>
      <SEO title={seoTitle} description={seoMetaDescription} image={seoImage} />
      <Layout setTitle={"About  US"} tag="about-us">
        {aboutuspageContent.data ? (
          <>
            {/* <!-- Banner --> */}

            <section className="aboutus">
              <div
                className="home-banner"
                style={{
                  backgroundImage: `url(${
                    aboutuspageContent.data.aboutUsPage.bannerImage
                      ? process.env.BACKEND_URL +
                        aboutuspageContent.data.aboutUsPage.bannerImage.url
                      : null
                  })`,
                  backgroundAttachment: "fixed",
                }}
              >
                <div className="banner-overlay">
                  <div className="smalltext">
                    <img className="arrow" src={path_img} alt="" />
                    <Link to="/">/ ABOUT US</Link>
                  </div>
                  <div className="text">
                    {aboutuspageContent.data.aboutUsPage.bannerTitle
                      ? aboutuspageContent.data.aboutUsPage.bannerTitle
                      : ""}
                  </div>
                  {aboutuspageContent.data.aboutUsPage.bannerDescription ? (
                    <div className="context">
                      <div className="quoteimg top">
                        <img src={quote} alt="" />
                      </div>
                      <p className="white">
                        {aboutuspageContent.data.aboutUsPage
                          .bannerDescription ? (
                          <ReactMarkdown
                            source={
                              aboutuspageContent.data.aboutUsPage
                                .bannerDescription
                            }
                          />
                        ) : (
                          ""
                        )}
                      </p>
                      <div className="quoteimg bottom">
                        <img src={quote} alt="" />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </section>
            {/* <!-- /banner --> */}
            {/* <!-- main --> */}
            <main className="about home">
              <div className="main-content">
                {aboutuspageContent.data.aboutUsPage.aboutDimensionsContent ? (
                  <div className="container about-dimensions">
                    <div className="about___dimensions__heading">
                      <h5 className="dimensions___title">
                        {aboutuspageContent.data.aboutUsPage
                          .aboutDimensionsContent.title
                          ? aboutuspageContent.data.aboutUsPage
                              .aboutDimensionsContent.title
                          : ""}
                      </h5>
                    </div>
                    <div className="row about___dimensions">
                      <div className="col-md-5">
                        <div className="about___image">
                          <img
                            src={
                              aboutuspageContent.data.aboutUsPage
                                .aboutDimensionsContent.image
                                ? process.env.BACKEND_URL +
                                  aboutuspageContent.data.aboutUsPage
                                    .aboutDimensionsContent.image.url
                                : null
                            }
                            className="about___dimensions_fig"
                            alt={
                              aboutuspageContent.data.aboutUsPage
                                .aboutDimensionsContent.imageAltName
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="about___content">
                          <p>
                            {aboutuspageContent.data.aboutUsPage
                              .aboutDimensionsContent.description ? (
                              <ReactMarkdown
                                source={
                                  aboutuspageContent.data.aboutUsPage
                                    .aboutDimensionsContent.description
                                }
                              />
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /container ---> */}
                  </div>
                ) : null}
                {/* <!-- second section --> */}
                {aboutuspageContent.data.aboutUsPage.ourStoryContent ? (
                  <div className="our___story">
                    <div className="container">
                      <div className="our___story__heading">
                        <h5 className="our___story__title">
                          {aboutuspageContent.data.aboutUsPage.ourStoryMainTitle
                            ? aboutuspageContent.data.aboutUsPage
                                .ourStoryMainTitle
                            : ""}
                        </h5>
                      </div>
                      <div className="our___stories">
                        {aboutuspageContent.data.aboutUsPage.ourStoryContent.map(
                          (content, index) => (
                            <div className="story___1" key={content.id}>
                              <div className="card">
                                <div className="card___heading">
                                  <h5 className="card___year">
                                    {content.year}
                                  </h5>
                                </div>
                                <div className="card___body">
                                  <div className="card___image">
                                    <img
                                      src={
                                        content.thumbnailImage
                                          ? process.env.BACKEND_URL +
                                            content.thumbnailImage.url
                                          : null
                                      }
                                      alt={content.thumbnailImageAltName}
                                    />
                                  </div>
                                  <div className="card___content">
                                    <h4 className="card___title">
                                      {content.title}
                                    </h4>
                                    <p className="card___message">
                                      {content.description ? (
                                        <ReactMarkdown
                                          source={content.description}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* <!-- second section end --> */}

                {/* <!-- third section --> */}
                {aboutuspageContent.data.aboutUsPage.ourMissionContent ? (
                  <div className="container our-mission">
                    <div className="about___dimensions__heading">
                      <h5 className="dimensions___title">
                        {aboutuspageContent.data.aboutUsPage.ourMissionContent
                          .title
                          ? aboutuspageContent.data.aboutUsPage
                              .ourMissionContent.title
                          : ""}
                      </h5>
                    </div>
                    <div className="row about___dimensions">
                      <div className="col-md-6">
                        <div className="about___image">
                          <img
                            src={
                              aboutuspageContent.data.aboutUsPage
                                .ourMissionContent.image
                                ? process.env.BACKEND_URL +
                                  aboutuspageContent.data.aboutUsPage
                                    .ourMissionContent.image.url
                                : null
                            }
                            className="about___dimensions_fig"
                            alt={
                              aboutuspageContent.data.aboutUsPage
                                .ourMissionContent.imageAltName
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="about___content">
                          {aboutuspageContent.data.aboutUsPage.ourMissionContent
                            .description ? (
                            <ReactMarkdown
                              source={
                                aboutuspageContent.data.aboutUsPage
                                  .ourMissionContent.description
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <!-- /container ---> */}
                  </div>
                ) : null}
                {/* <!-- third section end --> */}

                {/* <!-- fourth section --> */}
                {aboutuspageContent.data.aboutUsPage.ourValuesContent ? (
                  <div className="container our__mission">
                    <div className="our___values__heading">
                      <h5 className="our___values___title">
                        {aboutuspageContent.data.aboutUsPage.ourValuesContent
                          .mainTitle
                          ? aboutuspageContent.data.aboutUsPage.ourValuesContent
                              .mainTitle
                          : ""}
                      </h5>
                    </div>
                    <div className="row our___values">
                      <div className="col-md-6">
                        <Accordion
                          allowZeroExpanded
                          className={"our___values__acor"}
                        >
                          {aboutuspageContent.data.aboutUsPage.ourValuesContent.ourValueSubContent.map(
                            (content, index) => (
                              <AccordionItem
                                className={"values___accor__item"}
                                onClick={() => {
                                  setValue(index)
                                }}
                              >
                                <AccordionItemHeading>
                                  <AccordionItemButton>
                                    {content.title}
                                  </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                  <p>{content.description}</p>
                                </AccordionItemPanel>
                              </AccordionItem>
                            )
                          )}
                        </Accordion>
                      </div>
                      <div className="col-md-6">
                        {aboutuspageContent.data.aboutUsPage.ourValuesContent.ourValueSubContent.map(
                          (content, index) => (
                            <>
                              {index === value ? (
                                <div className="our___values_fig">
                                  <img
                                    src={
                                      content.image
                                        ? process.env.BACKEND_URL +
                                          content.image.url
                                        : null
                                    }
                                    alt={content.imageAltName}
                                  />
                                </div>
                              ) : null}
                            </>
                          )
                        )}
                      </div>
                    </div>
                    {/* <!-- /container ---> */}
                  </div>
                ) : null}
                {/* <!-- fourth section --> */}

                {/* <!-- fifth section --> */}
                {aboutuspageContent.data.aboutUsPage.ourCultureContent ? (
                  <div className="container our__culture">
                    <div className="about___dimensions__heading">
                      <h5 className="dimensions___title">
                        {aboutuspageContent.data.aboutUsPage.ourCultureContent
                          .title
                          ? aboutuspageContent.data.aboutUsPage
                              .ourCultureContent.title
                          : ""}
                      </h5>
                    </div>
                    <div className="row about___dimensions">
                      <div className="col-md-5">
                        <div className="about___image">
                          <img
                            src={
                              aboutuspageContent.data.aboutUsPage
                                .ourCultureContent.image
                                ? process.env.BACKEND_URL +
                                  aboutuspageContent.data.aboutUsPage
                                    .ourCultureContent.image.url
                                : null
                            }
                            className="about___dimensions_fig"
                            alt={
                              aboutuspageContent.data.aboutUsPage
                                .ourCultureContent.imageAltName
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="about___content">
                          {aboutuspageContent.data.aboutUsPage.ourCultureContent
                            .description ? (
                            <ReactMarkdown
                              source={
                                aboutuspageContent.data.aboutUsPage
                                  .ourCultureContent.description
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    {/* <!-- /container ---> */}
                  </div>
                ) : null}
                {/* <!-- fifth section end --> */}

                <div className="container our__culture">
                  {/* {aboutuspageContent.data.aboutUsPage.joinUSContent?
                <div className="row">
                  <div className="col-12">
                    <div className="join___us">
                      <div className="join___us__heading">
                        <h5 className="join___us__title">
                          {
                            aboutuspageContent.data.aboutUsPage.joinUSContent.mainTitle?
                            aboutuspageContent.data.aboutUsPage.joinUSContent.mainTitle:""
                          }
                        </h5>
                      </div>
                      <div className="join___us__content">
                        <p className="join___message">
                            {aboutuspageContent.data.aboutUsPage.joinUSContent.description?
                              <ReactMarkdown
                              source={aboutuspageContent.data.aboutUsPage.joinUSContent.description}
                              />
                          :""}
                        </p>
                        <div className="talk">
                          <button type="button" className="btn___joinus">
                            {aboutuspageContent.data.aboutUsPage.joinUSContent.buttonName?
                              aboutuspageContent.data.aboutUsPage.joinUSContent.buttonName:""
                            }
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              :null} */}
                  {aboutuspageContent.data.aboutUsPage.getInTouchContent ? (
                    <div className="touch___withus consultation">
                      <div className="row touch___withus__row">
                        <div className="col-md-6 text">
                          <div className="touch___withus__heading adventure">
                            <h5 className="title">
                              {aboutuspageContent.data.aboutUsPage
                                .getInTouchContent.mainTitle
                                ? aboutuspageContent.data.aboutUsPage
                                    .getInTouchContent.mainTitle
                                : ""}
                            </h5>
                          </div>
                          <div className="touch___withus__content">
                            <p className="touch___withus__message">
                              {aboutuspageContent.data.aboutUsPage
                                .getInTouchContent.description ? (
                                <ReactMarkdown
                                  source={
                                    aboutuspageContent.data.aboutUsPage
                                      .getInTouchContent.description
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </p>
                            {process.env.FILLOUT_ENQUIRY_FORM?
                            <div className="talk">
                              <Link
                                to={`${process.env.FILLOUT_ENQUIRY_FORM}`}
                                style={{ textDecoration: "none" }}
                                className="btn___joinus"
                              >
                                {aboutuspageContent.data.aboutUsPage
                                  .getInTouchContent.buttonName
                                  ? aboutuspageContent.data.aboutUsPage
                                      .getInTouchContent.buttonName
                                  : ""}
                              </Link> 
                            </div>
                            :null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="touch___withus_fig">
                            <img
                              src={
                                aboutuspageContent.data.aboutUsPage
                                  .getInTouchContent.thumbnailImage
                                  ? process.env.BACKEND_URL +
                                    aboutuspageContent.data.aboutUsPage
                                      .getInTouchContent.thumbnailImage.url
                                  : null
                              }
                              alt={
                                aboutuspageContent.data.aboutUsPage
                                  .getInTouchContent.thumnailImageAltName
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </main>
          </>
        ) : null}
      </Layout>
    </>
  )
}
export default AboutUS

export const query = graphql`
  query {
    allStrapiAboutUsPage {
      edges {
        node {
          metaDescription
          title
        }
      }
    }
    allStrapiHomePage {
      edges {
        node {
          metaImage {
            url
          }
        }
      }
    }
  }
`
